<template>
  <div class="home">
    <div class="col left">
      <div class="cont-inner-nav">
        <elemNav
          v-for="(nav, index) in componentList"
          :key="index"
          :click="() => changeTab(index)"
          :elem="nav"
          :id="`elem-simplenav${index}`"
        >
        </elemNav>
      </div>
      <component :is="this.leftComponent" v-bind="leftProps" v-if="compLeft === undefined"/>
      <component :is="this.leftComponent" v-else />
    </div>
    <div class="separcol"></div>
    <div class="col right">
      <component :is="this.currentComponent" v-bind="currentProps" />
    </div>
  </div>
</template>

<script>
import elemNav from '@/components/navigation/partialNavigation/ElemNav.vue';

export default {
  name: 'Home',
  components: {
    elemNav,
  },
  props: ['componentList', 'compLeft'],
  computed: {
    currentComponent() {
      return this.componentList[this.$store.state.homeNavIndex].comp;
    },
    leftComponent() {
      let index;
      if (this.compLeft === undefined) {
        index = (this.$store.state.homeNavIndex - 1) >= 0
          ? this.$store.state.homeNavIndex - 1
          : (this.componentList.length - 1);
        return this.componentList[index].comp;
      }
      index = 0;
      return this.compLeft[index].comp;
    },
    currentProps() {
      return this.componentList[this.$store.state.homeNavIndex].props || {};
    },
    leftProps() {
      let index;
      if (this.compLeft === undefined) {
        index = (this.$store.state.homeNavIndex - 1) >= 0
          ? this.$store.state.homeNavIndex - 1
          : (this.componentList.length - 1);
      } else {
        index = 0;
      }
      return this.componentList[index].props || {};
    },
  },
  mounted() {
    this.updateClass();
  },
  methods: {
    updateClass() {
      document.querySelectorAll('.elem_global_nav').forEach((elem) => {
        elem.classList.remove('current');
      });
      document.getElementById(`elem-simplenav${this.$store.state.homeNavIndex}`).classList.add('current');
    },
    changeTab(index) {
      this.$store.dispatch('navigateHomeTab', index);
      this.updateClass();
    },
  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  // .home{
  //   width: 100%;
  //   display: flex;
  //   flex-direction: row;
  //   text-align: left;
  //   min-height: 100vh;
  //   .col{
  //     display:flex;
  //     flex-direction: column;
  //     &.left{
  //       width: 400px;
  //       // background-color: $color_red;
  //       .cont-inner-nav{
  //         display: flex;
  //         flex-direction: row;
  //         justify-content: space-between;

  //         .test-elem-sub-nav{
  //           position: relative;
  //           height:70px;
  //           width:70px;
  //           margin: 10px 0;
  //           border-radius: 50%;
  //           background-color: $fond_cta;
  //           cursor: pointer;
  //           transition: all .2s;
  //           &:first-child{
  //             margin-right: 0;
  //           }
  //           &:hover{
  //             background-color: $fond_cta_hover;
  //           }
  //         }
  //       }
  //     }
  //     &.right{
  //       width: 840px;
  //     }
  //   }
  // }
</style>
