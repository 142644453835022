<template>
  <div
    class="elem_global_nav radius box-shadow"
    :key="elem.name"
    @click.exact="click"
    @click.ctrl="rightClick"
    @auxclick="rightClick"

    v-tooltip.top-center="{
      content: elem.name,
      delay: {
        show: 0,
        hide: 0,
      }
    }"
  >
    <img
      v-if="elem.picto && elem.nametab !== current"
      :src="require(`@/assets/pictos/${elem.picto}.svg`)"
    >
    <img
      v-if="elem.picto && elem.nametab === current"
      :src="require(`@/assets/pictos/${elem.selected_picto}.svg`)"
    >
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ElemNav',
  props: {
    click: Function,
    elem: {},
    rightClick: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState('search', ['current']),
  },
  components: {
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/_variables.scss";
    .elem_global_nav{
      margin-right: 1vh;
      background-color: #ffffff;
      width: calc(calc(calc(100% - 3vh) / 4 ));
      cursor: pointer;
      &:last-child{
        margin-right: 0;
      }
      &.current{
        background-color: $logo_color;
      }
      &:hover{
        background-color: $green_color;
      }
    }
</style>
