<template>
  <!-- <home :componentList="componentList"  /> -->
  <SimpleNavIntern :componentList="componentList"></SimpleNavIntern>
</template>

<script>

import OfferList from '@/views/Talent/TalentDashboard/Offer/OfferList.vue';
// import Home from '@/components/UniversalComponents/HomeNav.vue';
import SimpleNavIntern from '@/components/UniversalComponents/SimpleNavIntern.vue';
import FeedView from '@/views/Universal/FeedView.vue';
import Calendar from '@/components/UniversalComponents/Calendar/calendarView.vue';
// import EmployeeManagement from './EmployeeManagement.vue';

export default {
  name: 'TalentHome',
  components: {
    SimpleNavIntern,
  },
  data() {
    return {
      componentList: [
        {
          comp: OfferList,
          picto: 'picto_application',
        },
        {
          comp: FeedView,
          picto: 'picto_feed',
        },
        {
          comp: Calendar,
          picto: 'picto_calendar',
        },
      ],
    };
  },

};
</script>
